import { lazy, Suspense } from 'react'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { createTheme, ThemeProvider, CssBaseline } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

import LoadingPage from '@/components/LoadingPage'
import ErrorPage from '@/components/ErrorPage'
// import { lazyLoad } from './helpers/LazyLoad'

//PAGES
const Home = lazy(() => import('@/pages/Home'))
const Login = lazy(() => import('@/pages/Login'))
const Users = lazy(() => import('@/modules/users'))
const LoginNewStudies = lazy(() => import('@/pages/LoginNewStudies'))
const PrintModules = lazy(() => import('@/pages/PrintModules'))
const LogManipulationModules = lazy(() => import('@/pages/LogManipulationModules'))
const DataEntryModules = lazy(() => import('@/pages/DataEntryModules'))
const DataAccessEvaluationModules = lazy(() => import('@/pages/DataAccessEvaluationModules'))
const StabilityMaintenanceModules = lazy(() => import('@/pages/StabilityMaintenanceModules'))
const SystemMaintenanceModules = lazy(() => import('@/pages/SystemMaintenanceModules'))
const InProgress = lazy(() => import('@/pages/inProgress'))

//LOGIN NEW STUDIES MODULES
const NewStudyLogin = lazy(() => import('@/modules/login-new-studies/NewStudyLogin'))

import './App.css'
//ROUTES
const router = createBrowserRouter([
  {
    path: '/',
    element: <Home />,
    errorElement: <ErrorPage />
  },
  {
    path: '/login',
    element: <Login />,
    errorElement: <ErrorPage />
  },
  {
    path: '/users',
    element: <Users />,
    errorElement: <ErrorPage />
  },
  //MODULE ROUTES

  //LOGIN NEW STUDIES
  {
    path: '/loginNewStudies',
    element: <LoginNewStudies />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: 'newStudyLogin',
        element: <NewStudyLogin />,
        errorElement: <ErrorPage />
      },
      {
        path: 'editStudyLogin',
        element: <InProgress />,
        errorElement: <ErrorPage />
      },
      {
        path: 'authorize',
        element: <InProgress />,
        errorElement: <ErrorPage />
      },
      {
        path: 'editLogReport',
        element: <InProgress />,

        errorElement: <ErrorPage />
      }
    ]
  },
  //LOG MANIPULATION MODULES
  {
    path: '/logManipulationModules',
    element: <LogManipulationModules />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: 'sampleScheduler',
        element: <InProgress />,
        errorElement: <ErrorPage />
      },
      {
        path: 'pendingStabilityRequests',
        element: <InProgress />,
        errorElement: <ErrorPage />
      },
      {
        path: 'pendingStabilityInventory',
        element: <InProgress />,
        errorElement: <ErrorPage />
      },
      {
        path: 'pendingStabilityCycleStartDate',
        element: <InProgress />,
        errorElement: <ErrorPage />
      },
      {
        path: 'pendingStabilityCycleEndDate',
        element: <InProgress />,
        errorElement: <ErrorPage />
      },
      {
        path: 'takeExtraInventoryForSample',
        element: <InProgress />,
        errorElement: <ErrorPage />
      },
      {
        path: 'restoreInventoryByStudySelection',
        element: <InProgress />,
        errorElement: <ErrorPage />
      },
      {
        path: 'inventoryStatusReport',
        element: <InProgress />,
        errorElement: <ErrorPage />
      },
      {
        path: 'editLogReport',
        element: <InProgress />,
        errorElement: <ErrorPage />
      }
    ]
  },
  //DATA ENTRY MODULES
  {
    path: '/dataEntryModules',
    element: <DataEntryModules />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: 'dataEntry',
        element: <InProgress />,
        errorElement: <ErrorPage />
      },
      {
        path: 'approvedDataEntryEdit',
        element: <InProgress />,
        errorElement: <ErrorPage />
      },
      {
        path: 'level1ApprovalData',
        element: <InProgress />,
        errorElement: <ErrorPage />
      },
      {
        path: 'finalApprovalData',
        element: <InProgress />,
        errorElement: <ErrorPage />
      },
      {
        path: 'cdsForm',
        element: <InProgress />,
        errorElement: <ErrorPage />
      },
      {
        path: 'destructionForm',
        element: <InProgress />,
        errorElement: <ErrorPage />
      },
      {
        path: 'editLogReport',
        element: <InProgress />,
        errorElement: <ErrorPage />
      }
    ]
  },

  //DATA ACCESS EVALUATION MODULES
  {
    path: '/dataAccessEvaluationModules',
    element: <DataAccessEvaluationModules />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: 'graphicsGenerator',
        element: <InProgress />,
        errorElement: <ErrorPage />
      },
      {
        path: 'singleProductByYear',
        element: <InProgress />,
        errorElement: <ErrorPage />
      },
      {
        path: 'sampleToSample',
        element: <InProgress />,
        errorElement: <ErrorPage />
      },
      {
        path: 'parameterOverlay',
        element: <InProgress />,
        errorElement: <ErrorPage />
      },
      {
        path: 'expirationSingleStudy',
        element: <InProgress />,
        errorElement: <ErrorPage />
      },
      {
        path: 'expirationMultipleStudies',
        element: <InProgress />,
        errorElement: <ErrorPage />
      },
      {
        path: 'studyResultsOOSReport',
        element: <InProgress />,
        errorElement: <ErrorPage />
      },
      {
        path: 'studyResultsOOTReport',
        element: <InProgress />,
        errorElement: <ErrorPage />
      }
    ]
  },

  //PRINT MODULES
  {
    path: '/printModules',
    element: <PrintModules />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: 'summaryReportGenerator',
        element: <InProgress />,
        errorElement: <ErrorPage />
      },
      {
        path: 'analystReportGenerator',
        element: <InProgress />,
        errorElement: <ErrorPage />
      },
      {
        path: 'stabilityDatabaseLogs',
        element: <InProgress />,
        errorElement: <ErrorPage />
      },
      {
        path: 'labelManager',
        element: <InProgress />,
        errorElement: <ErrorPage />
      },
      {
        path: 'labelRequest',
        element: <InProgress />,
        errorElement: <ErrorPage />
      },
      {
        path: 'labelPrinting',
        element: <InProgress />,
        errorElement: <ErrorPage />
      },
      {
        path: 'regressionReport',
        element: <InProgress />,
        errorElement: <ErrorPage />
      },
      {
        path: 'multipleRegressionReport',
        element: <InProgress />,
        errorElement: <ErrorPage />
      },
      {
        path: 'stabilityProtocolReport',
        element: <InProgress />,
        errorElement: <ErrorPage />
      },
      {
        path: 'cOfA',
        element: <InProgress />,
        errorElement: <ErrorPage />
      }
    ]
  },

  //STABILITY MAINTENANCE MODULES
  {
    path: '/stabilityMaintenanceModules',
    element: <StabilityMaintenanceModules />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: 'analyst',
        element: <InProgress />,
        errorElement: <ErrorPage />
      },
      {
        path: 'abbreviation',
        element: <InProgress />,
        errorElement: <ErrorPage />
      },
      {
        path: 'packagingDictionary',
        element: <InProgress />,
        errorElement: <ErrorPage />
      },
      {
        path: 'quickList',
        element: <InProgress />,
        errorElement: <ErrorPage />
      },
      {
        path: 'reasonForStudy',
        element: <InProgress />,
        errorElement: <ErrorPage />
      },
      {
        path: 'site',
        element: <InProgress />,
        errorElement: <ErrorPage />
      },
      {
        path: 'storageLocation',
        element: <InProgress />,
        errorElement: <ErrorPage />
      },
      {
        path: 'studyCondition',
        element: <InProgress />,
        errorElement: <ErrorPage />
      },
      {
        path: 'userDefinedFields',
        element: <InProgress />,
        errorElement: <ErrorPage />
      },
      {
        path: 'annualLogReport',
        element: <InProgress />,
        errorElement: <ErrorPage />
      },
      {
        path: 'labNumber',
        element: <InProgress />,
        errorElement: <ErrorPage />
      },
      {
        path: 'measure',
        element: <InProgress />,
        errorElement: <ErrorPage />
      },
      {
        path: 'specificationLibrary',
        element: <InProgress />,
        errorElement: <ErrorPage />
      }
    ]
  },

  //SYSTEM MAINTENANCE MODULES
  {
    path: '/systemMaintenanceModules',
    element: <SystemMaintenanceModules />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: 'admin',
        element: <InProgress />,
        errorElement: <ErrorPage />
      },
      {
        path: 'location',
        element: <InProgress />,
        errorElement: <ErrorPage />
      },
      {
        path: 'userMaintenance',
        element: <InProgress />,
        errorElement: <ErrorPage />
      },
      {
        path: 'userGroup',
        element: <InProgress />,
        errorElement: <ErrorPage />
      },
      {
        path: 'licenseManagement',
        element: <InProgress />,
        errorElement: <ErrorPage />
      },
      {
        path: 'systemAuditTrail',
        element: <InProgress />,
        errorElement: <ErrorPage />
      },
      {
        path: 'accessLogReport',
        element: <InProgress />,
        errorElement: <ErrorPage />
      },
      {
        path: 'editLogReport',
        element: <InProgress />,
        errorElement: <ErrorPage />
      }
    ]
  },

  {
    path: '/inProgress',
    element: <InProgress />
  }
])

//MUI THEME
const theme = createTheme({
  palette: {
    primary: {
      main: '#6f88b3',
      light: '#a3bfe2',
      dark: '#004ba0'
    },
    secondary: {
      main: '#a4b1c2',
      light: '#ff5c8d',
      dark: '#9a0036'
    },
    success: {
      main: '#4caf50',
      light: '#80e27e',
      dark: '#087f23'
    },
    error: {
      main: '#f44336',
      light: '#ff7961',
      dark: '#ba000d'
    },
    warning: {
      main: '#ff9800',
      light: '#ffc947',
      dark: '#c66900'
    },
    info: {
      main: '#2196f3',
      light: '#6ec6ff',
      dark: '#0069c0'
    },
    text: {
      primary: '#212121',
      secondary: '#757575'
    },
    background: {
      default: '#fafafa',
      paper: '#ffffff'
    },
    divider: '#e0e0e0'
  },
  shape: {
    borderRadius: 8
  },
  typography: {
    fontFamily: 'Figtree, Roboto, sans-serif',
    h1: {
      fontSize: '2rem',
      fontWeight: 500
    },
    h2: {
      fontSize: '1.75rem',
      fontWeight: 500
    },
    h3: {
      fontSize: '1.5rem',
      fontWeight: 500
    },
    h4: {
      fontSize: '1.25rem',
      fontWeight: 500
    },
    h5: {
      fontSize: '1.125rem',
      fontWeight: 500
    },
    h6: {
      fontSize: '1rem',
      fontWeight: 500
    },
    body1: {
      fontSize: '1rem',
      fontWeight: 400
    },
    body2: {
      fontSize: '0.875rem',
      fontWeight: 400
    },
    caption: {
      fontSize: '0.75rem',
      fontWeight: 400
    }
  }
})

function App() {
  return (
    <Suspense fallback={<LoadingPage />}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <RouterProvider router={router} />
        </LocalizationProvider>
      </ThemeProvider>
    </Suspense>
  )
}

export default App
