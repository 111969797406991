import { useRouteError } from 'react-router-dom'
import { Container, Typography, Paper } from '@mui/material'

export default function ErrorPage() {
  const error = useRouteError()
  console.error(error)

  return (
    <Container
      component={Paper}
      elevation={3}
      id='error-page'
      sx={{
        padding: 3,
        textAlign: 'center',
        backgroundColor: '#f8d7da',
        color: '#721c24',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '50vh',
        width: '50vw',
        margin: 'auto',
        marginTop: '25vh'
      }}
    >
      <Typography
        variant='h1'
        component='h1'
        gutterBottom
      >
        Oops!
      </Typography>
      <Typography
        variant='body1'
        gutterBottom
      >
        Sorry, an unexpected error has occurred.
      </Typography>
      <Typography
        variant='body2'
        component='i'
      >
        {error.statusText || error.message}
      </Typography>
    </Container>
  )
}
